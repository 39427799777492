.start-now,
.start-now:active {
  font-size: 25px;
  color: #c80000;
  border: 2px solid #c80000;
  border-radius: 25px;
  padding: 10px 10px;
  transition-duration: 0.4s;
}

.start-now:hover {
  color: #fff;
  border: 2px solid #c80000;
  background-color: #c80000;
}

.review-resume,
.review-resume:active {
  font-size: 25px;
  color: #fff;
  border: 2px solid #c80000;
  background-color: transparent;
  border-radius: 25px;
  padding: 10px 10px;
  transition-duration: 0.4s;
  margin-right:10px;
  margin-bottom: 10px;
  width: auto;
}

.review-resume:hover {
  color: #fff;
  border: 2px solid #c80000;
  background-color: #c80000;
}

.form-title {
  border: none;
  outline: none;
  font-size: 30px;
  font-weight: bold;
}

.username {
  text-align: center;
  text-transform: capitalize;
  font-weight: bold;
}

.floating-btn {
  width: 70px;
  height: 70px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9999;
}

.container {
  overflow: hidden;
}

.content-name {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.75;
  color: #000000;
  text-transform: uppercase;
}

.nav-custom-menu {
  right: 115px;
  left: auto;
  top: auto !important;
}

.payment-btn {
  margin-top: 20px;
  width: 100%;
  border-radius: 5px;
  padding: 0.5rem;
  background: #c80000;
  border-color: #c80000;
  color: #fff;
}


@media screen and (max-width: 450px) {
  .nav-custom-menu {
    right: 0px;
  }

  .navbar-brand {
    z-index: 9;
  }
}

.stripeSec {
  /* border:1px solid #e6e6e6;
  background-color: #fafafa; */
  /* padding: 5px; */
  /* border-radius: 5px; */
}

.stripeSec label {
  font-size: 0.93rem;
}




.StripeElement {
  display: block;
  margin: 0px 0 10px 0;
  background-color: #fff;
  padding: 10px 14px;
  font-size: 1em;
  font-family: "Source Code Pro", monospace;
  border: 1px solid #e6e6e6;
  outline: 0;
  border-radius: 5px;
}

.StripeElement--focus {
  border: 1px solid #c80000;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.StripeElement.IdealBankElement,
.StripeElement.FpxBankElement,
.StripeElement.PaymentRequestButton {
  padding: 0;
}

.StripeElement.PaymentRequestButton {
  height: 40px;
}

.fables-team img{
  aspect-ratio: 30/37;
  object-fit: cover;
}

.image-overlay{
  position: absolute;
}

.no-hide{
  overflow: initial !important;
}