
/*=-====Bootstrapthemes.co btco-hover-menu=====*/

.navbar-light .navbar-nav .nav-link {
    color: rgb(64, 64, 64);
}
.btco-hover-menu a ,  .navbar > li > a {
    text-transform: capitalize;
    padding: 10px 15px;
    color: #666;
    font-size: 15px;
}

.btco-hover-menu .active a,
.btco-hover-menu .active a:focus,
.btco-hover-menu .active a:hover,
.btco-hover-menu li a:hover,
.btco-hover-menu li a:focus ,
.navbar>.show>a,  .navbar>.show>a:focus,  .navbar>.show>a:hover{
    color: #E54D42;
    background: transparent;
    outline: 0;
}
.fables-nav .dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
    float: right;
    margin-right: 5px;
    margin-top: 8px;
}
.fables-nav .nav-link {
    color: rgb(255, 255, 255);
    font-size: 15px;
    font-weight: 400;
    transition: all 0.3s ease-in-out;
    padding: 10px 15px !important;
}
.fables-nav .nav-link:hover {
    color: rgb(229, 77, 66);
}
/*submenu style start from here*/


.fables-nav .dropdown-menu {
    padding: 0px 0; 
    margin: 0 0 0; 
    border: 0px solid transition !important;
    border: 0px solid rgba(0,0,0,.15);	
    border-radius: 0px;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;

}

/*first level*/
.btco-hover-menu .collapse ul > li:hover > a{background: transparent;color: rgb(229, 77, 66);}
 
/*second level*/
.btco-hover-menu .collapse ul ul ul > li:hover > a{background: #fff;}

/*third level*/
.btco-hover-menu .collapse ul ul, .btco-hover-menu .collapse ul ul.dropdown-menu{background:#f5f5f5;}
 
.btco-hover-menu .collapse ul ul ul ul, .btco-hover-menu .collapse ul ul ul ul.dropdown-menu{background:#f5f5f5}

/*Drop-down menu work on hover*/
.btco-hover-menu{background: none;margin: 0;padding: 0;min-height:20px}

@media only screen and (max-width: 991px) {
    .btco-hover-menu .show > .dropdown-toggle::after{
        transform: rotate(-90deg);
    }
    .btco-hover-menu .collapse ul ul ul ul, .btco-hover-menu .collapse ul ul ul ul.dropdown-menu , .btco-hover-menu .collapse ul ul, .btco-hover-menu .collapse ul ul.dropdown-menu{ 
        padding-left: 25px;
    }
}
@media only screen and (min-width: 991px) {
    .fables-nav .dropdown-toggle::after {
    display: inline-block;
    width: 0px;
    height: 0px;
    vertical-align: 0.255em;
    content: "\e90b";
    font-size: 5px;
    font-family: fables !important;
    border-top: 0.3em solid;
    border-right: 0px;
    border-bottom: 0px;
    border-left: 0px;
    margin: 0px 7px;
}
.fables-nav .dropdown-toggle::after {
    position: absolute;
    right: 3px;
    top: 10px; 
    display: inline-block;
    width: 0px;
    height: 0px;
    vertical-align: 0.255em;
    content: "\e90b";
    font-size: 5px;
    font-family: fables !important;
    border-top: 0.3em solid;
    border-right: 0px;
    border-bottom: 0px;
    border-left: 0px;
    margin: 8px 7px; 
}
.fables-nav .dropdown-menu:before {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 24px;
    border-right: 5px solid transparent;
    border-bottom: 5px solid rgba(255,255,255,0.9);
    border-left: 5px solid transparent;
}
.fables-nav li li > .dropdown-menu:before {
    top: 22px;
    right: 100%;
    bottom: auto;
    left: auto;
    border-top: 5px solid transparent;
    border-right: 5px solid rgba(255,255,255,0.9);
    border-bottom: 5px solid transparent;
}
    .btco-hover-menu .collapse ul li{position:relative;padding: 0 10px;}
    .btco-hover-menu .collapse ul li:hover> ul{display:block}
    .btco-hover-menu .collapse ul ul{position:absolute;top:100%;left:0;min-width:250px;display:none}
    /*******/
    .btco-hover-menu .collapse ul ul li{position:relative}
    .btco-hover-menu .collapse ul ul li:hover> ul{display:block}
    .btco-hover-menu .collapse ul ul ul{position:absolute;top:0;left:103%;min-width:250px;display:none}
    /*******/
    .btco-hover-menu .collapse ul ul ul li{position:relative}
    .btco-hover-menu .collapse ul ul ul li:hover ul {
        display: block;
        left: 103%;
    }
    .btco-hover-menu .collapse ul ul ul ul{position:absolute;top:0;left:103% !important;min-width:250px;display:none;z-index:1}

}
.fables-nav .dropdown-menu .dropdown-toggle::after { 
    right: 14px; 
}