:root {
  /* --primary: #00ffef;
  --secondary: #007fff; */
  --primary: #000;
  --secondary: #fff;
  --text-color: #ffffff;
  --first-color: rgb(30, 37, 50);
  --second-color: #c80000;
}

.main {
  min-height: 100vh;
  background-color: var(--primary);
  color: var(--text-color);
}

.mainLogo {
  width: 100%;
  height: calc(100vh - 200px);
  min-height: 260px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* .btn {
  border-radius: 25px !important;
  padding: 10px 40px !important;
}

.btn-primary {
  border-color: var(--secondary) !important;
  background-color: var(--secondary) !important;
  color: black !important;
}

.btn-outline-primary {
  border-color: var(--secondary) !important;
  color: - var(--secondary) !important;
} */

sup {
  color: #f00;
}

.cursor-pointer {
  cursor: pointer;
}

.preview-container {
  /* background-color: var(--secondary); */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin: 0px auto 0px;
  border: 1px solid #dad7d7;
  border-radius: 3px;
}



.files input {
  background: transparent;
  outline: 2px dashed var(--secondary);
  outline-offset: -10px;
  -webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
  transition: outline-offset .15s ease-in-out, background-color .15s linear;
  padding: 120px 0px 85px 35%;
  text-align: center !important;
  margin: 0;
  width: 100% !important;
  color: var(--text-color);
}

.files input:focus {
  background: transparent;
  color: var(--text-color);
  outline: 2px dashed var(--secondary);
  outline-offset: -10px;
  -webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
  transition: outline-offset .15s ease-in-out, background-color .15s linear;
  border: 1px solid var(--secondary);
}

.files {
  position: relative
}

.files .form-control {
  border-color: var(--secondary);
}

/* .files:after {
  pointer-events: none;
  position: absolute;
  top: 60px;
  left: 0;
  width: 50px;
  right: 0;
  height: 56px;
  content: "";
  background-image: url(https://image.flaticon.com/icons/png/128/109/109612.png);
  display: block;
  margin: 0 auto;
  background-size: 100%;
  background-repeat: no-repeat;
} */

.files:before {
  position: absolute;
  bottom: 10px;
  left: 0;
  pointer-events: none;
  width: 100%;
  right: 0;
  height: 57px;
  content: "click here to upload your file or drag it here. ";
  display: block;
  margin: 0 auto;
  color: #2ea591;
  font-weight: 600;
  text-transform: capitalize;
  text-align: center;
}

.hr-text {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  opacity: 0.5;
}

.hr-text:before {
  content: "";
  background: linear-gradient(to right, transparent, #818078, transparent);
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 1px;
}

.hr-text:after {
  content: attr(data-content);
  position: relative;
  display: inline-block;
  padding: 0 0.5em;
  line-height: 1.5em;
  color: var(--secondary);
  background-color: white;
}

.payment-modal .modal-content {
  border-radius: 10px !important;
}

.payment-modal .modal-footer {
  border: none;
}

.reviews-owl .owl-prev,
.reviews-owl .owl-next {
  background-color: transparent !important;
}

.reviews-owl .owl-prev:hover,
.reviews-owl .owl-next:hover {
  color: #E54D42 !important;
}

.user-details .user-form {
  max-height: calc(100vh - 75px);
  overflow-y: scroll;
}

.user-details .user-form::-webkit-scrollbar,
.preview-sec::-webkit-scrollbar {
  width: 3px;
}

.preview-sec::-webkit-scrollbar {
  width: 0px !important;
  height: 0px !important;
}

/* Track */
.user-details .user-form::-webkit-scrollbar-track,
.preview-sec::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.user-details .user-form::-webkit-scrollbar-thumb,
.preview-sec::-webkit-scrollbar-thumb {
  background: #E54D42;
}

/* Handle on hover */
.user-details .user-form::-webkit-scrollbar-thumb:hover,
.preview-sec::-webkit-scrollbar-thumb:hover {
  background: #E54D42;
}


.user-details .tab-content {
  min-height: 250px;
}

.user-details .card-header {
  border-bottom: 0;
  background-color: transparent;
  border-radius: 0;
  padding: 0;
}

.user-details .nav-tabs {
  background-color: #c80000;
  color: #FFFFFF;
  border-top-right-radius: 0.1875rem;
  border-top-left-radius: 0.1875rem;
  padding-top: 10px;
  padding-bottom: 10px;
}

.user-details .nav-tabs>.nav-item>.nav-link {
  color: #fff;
  margin: 0;
  margin-right: 5px;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 30px;
  font-size: 14px;
  padding: 11px 23px;
  line-height: 1.5;
}

.user-details .nav-tabs>.nav-item>.nav-link:hover {
  background-color: transparent;
}

.user-details .nav-tabs>.nav-item>.nav-link.active {
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 30px;
  color: #FFFFFF;
}

[data-background-color="orange"] {
  background-color: #c80000;
}

.form-label {
  font-size: 16px;
  line-height: 20px;
  color: rgb(130, 139, 162);
  margin-bottom: 0rem !important;
  text-transform: capitalize;
}

.form-control.common {
  border-radius: 0px !important;
  padding: 12px 16px !important;
  width: 100% !important;
  display: block !important;
  caret-color: #c80000 !important;
  background-color: rgb(239, 242, 249) !important;
  outline: none !important;
  border: 0px !important;
  color: rgb(30, 37, 50) !important;
  transition: color 0.1s ease 0s !important;
}

.form-control.common:focus {
  border-bottom: 1px solid #c80000 !important;
}

.error {
  font-size: 12px;
}

.add-btn {
  width: 100%;
  background: transparent !important;
  color: #c80000 !important;
  border: 1px dashed #c80000 !important;
}

.add-skill-btn {
  background-color: #c80000 !important;
  border-color: #c80000 !important;
}

.sub-title {
  font-size: 23px;
  line-height: 28px;
  font-weight: 600;
}

.sub-title-desc {
  margin-top: -10px;
  font-size: 14px;
  line-height: 20px;
}

.img-selector {
  color: rgb(190, 196, 213);
  transition: background-color 0.1s ease 0s, color 0.1s ease 0s;
  flex: 0 0 60px;
  width: 60px;
  height: 60px;
  border-radius: 3px;
  margin-right: 16px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  background-color: rgb(239, 242, 249);
  background-image: none;
  background-size: cover;
}

.img-label-sec {
  display: flex;
  flex-flow: column;
  -webkit-box-pack: center;
  justify-content: center;
}

.img-label {
  font-size: 16px;
  line-height: 20px;
  color: var(--second-color);
  transition: color 0.1s ease 0s;
}

.c-header {
  display: flex;
  justify-content: space-between;
  padding: 1rem !important;
  cursor: pointer;
}

.c-header:hover {
  color: var(--second-color);
}

.c-title {
  font-weight: 600;
}

.c:hover .c-delete i {
  visibility: visible;
}

.c-delete {
  width: 40px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -41px;
  top: 0px;
  cursor: pointer;
}

.c-delete i {
  visibility: hidden;
}

.c-delete:hover i {
  visibility: visible;
  color: var(--second-color);
}

.c-body {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.c .form-check-label {
  color: rgb(130, 139, 162) !important;
}

iframe {
  background-color: transparent !important;
}

.preview-sec {
  height: 100%;
  max-height: calc(100vh - 140px);
  aspect-ratio: 1/1.41;
  border-radius: 5px;
  overflow-y: scroll;
  /* overflow-x: hidden; */
  margin: auto;
  /* background-color: white; */
  display: flex;
  justify-content: center;
}

.download-btn {
  font-size: 18px;
  color: #fff;
  border: 2px solid #c80000;
  background-color: #c80000;
  border-radius: 10px;
  /* padding: 10px 10px; */
  transition-duration: 0.4s;
}

.holder {
  position: relative;
}

.middle {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* text-align: center; */
  background-color: rgba(0, 0, 0, 0.3);
}

.holder:hover .middle {
  opacity: 1;
}

.holder .text {
  background-color: rgba(0, 0, 0, 0.7);
  color: rgba(255, 255, 255, 1);
  padding: 5px 15px;
  border-radius: 7px;
}

.build-modal {
  border-radius: 16px !important;
  overflow: hidden;
}

.build-modal .heading {
  font-size: 27px;
  font-weight: 600;
}

.build-modal .heading-desc {
  font-size: 19px;
}

.modal-lg {
  max-width: 700px !important;
}

.build-modal .modal-header {
  border-bottom: none !important;
}

.build-modal .modal-body {
  padding: 3rem 3rem;
  padding-top: 0px !important;
}

.option-btn {
  height: 96px;
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  background-color: rgb(247, 249, 252);
  border-radius: 6px;
  color: var(--first-color);
  font-weight: bold;
}

.option-btn:hover,
.option-btn:active {
  color: var(--second-color);
  background-color: rgba(200, 0, 0, 0.1);
}

.option-btn img {
  width: 55px;
  margin-left: 10px;
  margin-right: 10px;
}

.option-btn .desc,
.option-btn:hover .desc {
  font-weight: normal;
  color: #4b4a45;
}

.fables-partner-carousel-img {
  max-height: 120px;
}

.main-preview {
  height: calc(100vh - 75px);
  transition-duration: 500ms;
  z-index: 9;
}

.preview-btn {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 0px;
  padding: 5px 15px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  outline: none;
  border: none;
  background: #c80000;
  color: #fff;
  font-weight: bold;
  z-index: 99;
}

.prevContainer {
  position: relative;
}

.zoom-sec {
  position: fixed;
  display: flex;
  flex-direction: column;
  z-index: 99;
  width: 30px;
  margin: 10px;
  bottom: 50px;
  right: 10px;
  row-gap: 5px;
  transition-duration: 500ms;
}

.zoom-sec button {
  width: 100%;
}



@media screen and (max-width: 767px) {

  .build-modal .modal-body {
    padding: 0px !important;
  }

  .option-btn .desc {
    display: none;
  }

}

@media screen and (max-width:1199px) {
  .main-preview {
    position: fixed !important;
    top: 0px;
    width: 100% !important;
    left: 100%;
    height: 100vh !important;
  }

  .main-preview.show {
    left: 0px !important;
  }

  .preview-btn {
    display: block;
  }

  .preview-sec {
    max-width: 100% !important;
  }

  .zoom-sec {
    /* display: none; */
    right: -100%
  }

  .main-preview.show .zoom-sec {
    right: 10px;
  }
}

@media screen and (max-width: 590px) {
  .icons-header-mobile {
    right: 0px !important;
  }
}

@media screen and (max-width:411px) {
  .review-resume {
    width: 100% !important;
    margin-right: 0px !important;
  }
}