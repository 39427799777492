.admin-login {
  width: 100%;
  height: calc(100vh - 75px);
  background-color: #ccc;
  background-image: url("./admin_bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.admin-login .login-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 600px;
  box-shadow: 10px 10px 5px #333;
  border-radius: 10px;
  overflow: hidden;
}

.admin-login .login-container .header {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  background-color: #c80000;
  color: #fff;
  padding-top: 10px;
  padding-bottom: 10px;
}

.admin-login .login-container .body {
  padding: 40px 15px;
  background-color: #fff;
}

.admin-login .login-container .body .login-btn {
  margin-top: 15px;
  font-size: 16px;
  color: #fff;
  border: 2px solid #c80000;
  background-color: #c80000;
  border-radius: 10px;
  padding: 5px 20px;
  transition-duration: 0.4s;
  width: 100%;
}

.admin {
  width: 100%;
  height: calc(100vh - 75px);
  background-image: url("./admin_bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  overflow-y: scroll;
}

.admin::-webkit-scrollbar {
  width: 5px;
}

.admin .table-responsive::-webkit-scrollbar{
  height: 5px;
}

.admin::-webkit-scrollbar-track,
.admin .table-responsive::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.admin::-webkit-scrollbar-thumb,
.admin .table-responsive::-webkit-scrollbar-thumb {
  background: red;
  border-radius: 10px;
}

/* Handle on hover */
.admin::-webkit-scrollbar-thumb:hover,
.admin .table-responsive::-webkit-scrollbar-thumb:hover {
  background: #b30000;
}